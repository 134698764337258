nav {
    display: flex !important;
    flex-direction: row;
    width: 100%;

    #menu {
        width: 100%;

        .menu-main-menu-de-container {
            width: 90%;
            max-width: 1200px;
            margin: auto;
 
            ul {
                width: 100%;
                padding: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                li {
                    list-style: none;

                    a {
                      
                        font-weight: 600;
                        letter-spacing: 1.4pt;
                        text-transform: uppercase;
                        font-size: 10pt;

                    }
                }
            }
        }
    }




    @media screen and (max-width: 800px) {
        #menu {
            .menu-main-menu-de-container {
                display: none;
                height: calc(100vh - 58px);
                position: absolute;
                width: 80vw;
                z-index: 100;
                left: -80vw;
                top: 58px;
                background-color: white;
                display: block;

                -webkit-transition: all .3s ease-out;
                -moz-transition: all .3s ease-out;
                -o-transition: all .3s ease-out;
                transition: all .3s ease-out;

                ul {
                    flex-direction: column;
                    justify-content: space-between;
                    width: 90%;
                    margin: auto;

                    li {
                        margin-bottom: 20px;
                        margin-top: 20px;

                        a {
                            font-size: 12pt; 
                        }
                    }
                }
            }
        }

        .open-nav#menu {

            .menu-main-menu-de-container {

                left: 0%;

            }
        }

    }




    a.lang {
        text-transform: uppercase;
        font-weight: 800;
        color: black;
        margin-left: 10px;
        margin-right: 10px;
        align-self: center;
    }

    .hamburger {
        padding: 20px 15px;
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;
        display: none;
        text-align: center;


        width: 40px;
        height: 18px;
        // padding-top: 15px;
    }

    @media screen and (max-width: 800px) {
        .hamburger {
            display: block;
        }
    }


    .hamburger:hover {
        opacity: 0.7;
    }

    .hamburger.is-active:hover {
        opacity: 0.7;
    }

    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
        background-color:#403e3c;
    }

    .hamburger-box {
        width: 40px;
        height: 12px;
        display: inline-block;
        position: relative;
    }

    // .hamburger-inner {
    //     display: block;
    //     top: 50%;
    //     margin-top: -2px;
    // }

    // .hamburger-inner, 
    .hamburger-inner::before,
    .hamburger-inner::after {
        width: 40px;
        height: 2px;
        background-color: #403e3c;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    .hamburger-inner::before,
    .hamburger-inner::after {
        content: "";
        display: block;
    }

    .hamburger-inner::before {
        top: 0;
    }

    .hamburger-inner::after {
        bottom: 0;
    }


    /*
   * Minus
   */
    .hamburger--minus .hamburger-inner::before,
    .hamburger--minus .hamburger-inner::after {
        transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
    }

    .hamburger--minus.is-active .hamburger-inner::before,
    .hamburger--minus.is-active .hamburger-inner::after {
        // opacity: 0;
        transition: bottom 0.08s ease-out top 0.08s ease-out, opacity 0s 0.08s linear;
    }

    .hamburger--minus.is-active .hamburger-inner::before {
        top: 50%;
        background-color: black;
    }

    .hamburger--minus.is-active .hamburger-inner::after {
        bottom: calc(50% - 2px); 
        background-color: black;
    }
}



/* Effect 5: same word slide in */
.cl-effect-5 li {
    overflow: hidden;
    // padding: 0 4px;
    height: 30px; 
}

.cl-effect-5 a {
    position: relative;
    display: inline-block;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;

    &:before {
        position: absolute;
        top: 100%;
        //content: attr(data-hover);
        content: 'Home';
        font-weight: 600;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.cl-effect-5 #menu-item-180 a {
    &:before {
        content: 'Apokalypse';
    }
}

.cl-effect-5 #menu-item-431 a {
    &:before {
        content: 'Überfluss';
    }
}

.cl-effect-5 #menu-item-178 a {
    &:before {
        content: 'Syntropie';
    }
}

.cl-effect-5 #menu-item-430 a {
    &:before {
        content: 'Die Hüter';
    }
}

.cl-effect-5 #menu-item-176 a {
    &:before {
        content: 'Gutes Leben';
    }
}


.cl-effect-5 a:hover,
.cl-effect-5 a:focus {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
}