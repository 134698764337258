#content-lightbox {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1001;

    #content-lightbox-inner {
        background-color: white;
        width: 80vw;
        height: 80vh;
        position: absolute;
        top: 10vh;
        bottom: 10vh;
        left: 10vw;
        right: 10vw;
        // transform: scale(0);
        // transform: scale(1); 

        -webkit-transform: scale(1);
        transform: scale(1); 
        -webkit-animation: transformer .5s ease-in 0s 1;
        animation: transformer .5s ease-in 0s 1; 
        

    } 
    #btn-close{
        position: absolute;
        width:20px;
        height:20px;
        right: 10vw;  
        top:calc(10vh - 30px);
        background-image:url('../../img/icon-close.svg');
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        cursor:pointer; 
    } 

}



@-webkit-keyframes transformer {
    from {
        -webkit-transform: scale(0.1);
    }

    to {
        -webkit-transform: scale(1);
    }
}

@keyframes transformer {
    from {
        transform: scale(0.1);
    }

    to {
        transform: scale(1);
    }
}
 

.insert-page{
    display:none;
}