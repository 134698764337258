.swipergallery{
    // .sixteen-nine {
    //     position: relative;
    //     overflow: hidden;
    //   }
    //   .sixteen-nine:before {
    //     display: block;
    //     content: " ";
    //     width: 100%;
    //     padding-top: 56.25%;
    //   }
    //   .sixteen-nine > .content {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //   }
    .vce-image-gallery-with-icon-item-inner-wrapper{
 
        position: relative;
        overflow: hidden;
      
      &:before {
        display: block;
        content: " ";
        width: 100%;
        padding-top: 56.25%;
      }
       .vce-image-gallery-with-icon-item-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }


    .vce-image-gallery-with-icon-list{
            .vce-image-gallery-with-icon-item{
                display:none;
                &:first-child{
                    display:block;
                }
            }
    }
}