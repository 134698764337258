.home {

    #stage {
        display: flex;
        width: 100%;
        height: 100vh;
        // background-color: lightgrey;
        // background-image:url(../../img/testbg-min.jpg);
        background-color: black;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        margin-bottom: 50px;


        #apply-now {
            position: absolute;
            bottom: 0;
            right: 5%;
            background-color: white;
            width: 500px;
            height: 0px;
            z-index: 2;
            display: flex;
            overflow: hidden;

            //justify-content:center;
            #apply-button {
                align-self: center;

                h3 {
                    margin: 0;
                    margin-left: 20px;
                }

                p {
                    margin: 0;
                    color: lightgrey;
                    margin-left: 20px;
                }
            }
        }

        #scroll-down {
            position: absolute;
            bottom: 30px;
            left: 5%;
            opacity: .5;
            color: white;
            z-index: 2;
        }
       

        video {
            position: absolute;
            right: 0;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
            z-index: 0;
            opacity: 0;
            -webkit-transition: all 1s ease-out;
            -moz-transition: all 1s ease-out;
            -o-transition: all 1s ease-out;
            transition: all 1s ease-out;

        }

        .fadeIn {
            opacity: 1;
        }

        // #overlay {
        //     display: block;
        //     background-color: rgba(0, 0, 0, 0);
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     bottom: 0;
        //     width: 100%;
        //     z-index: 1;
        // }

        // #cover {
        //     display: block;
        //     background-color: white;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     bottom: 0;
        //     width: 100%;
        //     z-index: 3;
        // }



        #stage-inner {
            align-self: center;
            position: relative;
            z-index: 10;
            // max-width: 1200px;
            margin: auto;
            width: 90%;

            h1 {
                color: white;
            }

            p {
                color: white;
                width: 60%;
                font-size: 3vw;
                margin: 0;
            }
        }
    }
 

    #submenu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media screen and (max-width: 800px) {
            flex-direction:column;
          }
        .menubullet {
            width: 30%;
            // opacity: .3;
            // -webkit-transition: all 1s ease-out;
            // -moz-transition: all 1s ease-out;
            // -o-transition: all 1s ease-out;
            // transition: all 1s ease-out;

            // &:hover {
            //     opacity: 1;
            // }
            @media screen and (max-width: 800px) {
                width:100%;
                margin-bottom:50px; 
              }
        }
    }

  


    .text-between {
        margin: 150px 0;
        text-align: center
    }

  


}