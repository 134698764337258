img {
  width: 100%;
}

#wrapper {
  // max-width: 1200px;
  // margin: auto;


  section {
    position: relative;

    // max-width: 1200px;
    // margin: auto;
    // margin-top: 150px;
    // margin-bottom: 150px;

    width: 100%;
  }

  .wide {
    width: 100%;
    max-width: 100%;
  }
}


a.more {
  position: relative;
  margin-left: 30px;

  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -30px;
    top: 10px;
    height: 2px;
    width: 25px;
    background-color: black;

    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }

  &:hover {
    margin-left: 40px;

    &:before {
      left: -40px;
      width: 35px;

    }
  }
}

.vce-row-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.vce-row {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}


.story {
  width: 100%;
  max-width: none;
  // background-color:black;
}

.fullscreen {
  width: 100%;
  height: 100vh;
  max-width: none;

  .vce-col {
    height: 100%;
    width: 100%;
  }

  // img{
  // width:100%;
  // }
  .vce-single-image-inner {
    width: 100% !important;
  }
}


#overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  display: none;
  z-index: 1;
}

// .crossover{
//   .vce-col{
//     &:first-child{
//       width:50% !important;
//       position:absolute;
//       background-color:green;
//     }
//     &:last-child{
//       width:60% !important;
//       position:absolute;
//       background-color:red;
//       right:0;
//       h2{
//         font-size:40vw; 
//       }
//     }
//   }
// }

.stage {
  width: 100%;
  // height:100vh;
  max-width: none;
  // overflow:hidden;
  // .vce-content-background-container{
  //   width:100%;
  //   height:100vw;
  // }

}

button {
  outline: none !important;
}
 
.metabox {
  // background-color: #C1B8A4;
  background-color:#EBE8E0; 
  hr{ 
  //border: .5px solid rgba(255,255,255,1); 
  border: .5px solid rgba(158, 149, 132,1);
       
   width:45px;   
  // width:90%;  
  margin-left:0%; 
 
  margin-top:40px; 
  margin-bottom:40px; 
  }    

  .vce-text-block {
    // padding: 20px;  
  }

  p,
  h2,
  h3,
  h4,
  h5, 
  a {
    //color: white;
    color:#736C5F;
  }

  h5{
    font-size: 12pt; 
    margin-bottom:50px; 
  }

  a,
  p {
    // font-family: neue-haas-grotesk-display, sans-serif;
    // font-weight: 500;
    // font-size: 10pt;
    // line-height: 20pt; 
    // letter-spacing: .7pt;
    // position: relative;
    // color: white;  

    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;
    font-size: 10pt;
    line-height: 18pt;
    letter-spacing: .5pt;
    position: relative;
    color: #736C5F;
  } 

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

// .vce-single-image-wrapper img{
//   width:100%;
// }

.hideme {
  position: relative;
  opacity: 0;
  top: 100px;
}

#audio-wrapper {
  width: 300px;
  position: absolute;
  bottom: 30px;
  left: 5vw;
  display: flex;
  justify-content: space-between;
  opacity:.7;  

  span {
    font-weight: 800;
    color: white;
    font-size: 9pt;
  }
} 

#equalizer {
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.bar {
  background-color: white;
  width: 8px;
  align-self: flex-end;
}

.bar1 {
  height: 50%;
  animation: equalizer 1.2s 0s infinite;
  -moz-animation: equalizer 1.2s 0s infinite;
  -webkit-animation: equalizer 1.2s 0s infinite;
  -o-animation: equalizer 1.2s 0s infinite;
}

.bar2 {
  height: 80%;
  animation: equalizer 0.8s 1s infinite;
  -moz-animation: equalizer 0.8s 1s infinite;
  -webkit-animation: equalizer 0.8s 1s infinite;
  -o-animation: equalizer 0.8s 1s infinite;
}

.bar3 {
  height: 20%;
  animation: equalizer 1.8s 2s infinite;
  -moz-animation: equalizer 1.8s 2s infinite;
  -webkit-animation: equalizer 1.8s 2s infinite;
  -o-animation: equalizer 1.8s 2s infinite;
}


/* Animation Keyframes*/
@keyframes equalizer {
  0% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@-moz-keyframes equalizer {
  0% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@-webkit-keyframes equalizer {
  0% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

@-o-keyframes equalizer {
  0% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}