.accordion {
    overflow: hidden;

    .accordion-wrapper {
        width: 100%;
        display: table;
        table-layout: fixed;
        margin: 0;
        padding: 0;
    }



    .acc-item {
        display: table-cell;
        vertical-align: bottom;
        position: relative;
        width: 16.666%;
        height: 60vh;
        background-repeat: no-repeat;
        background-position: center center;

        transition: all 1000ms ease-out;
        transition-timing-function: ease-in;

        transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        overflow: hidden;
    }

    .acc-item-content {
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        color: white;
        min-width: 400px;


        .acc-content {
            position: absolute;
            width: 90%;

            left: 0;
            z-index: 333;

            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            bottom: 40px;
            // background-color: red;

            .bottom-content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                position: relative;

                .text-content {
                    width: calc(100% - 50px);

                    .rect {
                        // width: calc(100% - 50px);
                        width: 100%;
                        background-color: white;
                        height: 2px;
                        align-self: flex-end;

                    }
                }



                .number {

                    font-family: neue-haas-grotesk-display, sans-serif;
                    font-size: 18pt;
                    color: white;
                    font-weight: 200;
                    text-align: right;
                    position: absolute;
                    bottom: -5px;
                    right: 0;
                    width: 50px;
                }
            }


            h3 {
                color: white;
                margin: 0;
                font-size: 18pt !important;
                margin: 0;
                font-weight: 700;
            }

            h2 {
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                /* "overflow"-Wert darf nicht "visible" sein */
                text-overflow: ellipsis;
                margin: 0;
                text-shadow: 1px 1px 2px #000000;
            }

            p {
                // height: 0;
                max-height: 0;
                overflow: hidden;
                width: 60%;
                color: white;
                font-size: 8pt;
                line-height: 12pt;

                -moz-transition: all .6s ease-out;
                -webkit-transition: all .6s ease-out;
                -o-transition: all .6s ease-out;
                transition: all .6s ease-out;
            }
        }


        transition: all 1000ms ease-out;
        background-position: center;
        background-size: auto 100%;
    }

    .acc-bg {
        background-color: black;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 222;
        transition: all 500ms ease-in-out;
        display: block;
    }

    .acc-item {
        width: 8%;
    }

    .acc-item:hover {
        width: 40%;
    }

    p.showText {
        // height: auto;
        max-height: 1000px;
        transition-delay: 1s;
    }

    p.hideText {
        -moz-transition: none;
        -webkit-transition: none;
        -o-transition: all 0 ease-in;
        transition: none;
    }

    // .acc-item:not(:hover) {
    //     // opacity:0.5;
    //     .acc-bg {
    //         opacity: .6;
    //     }
    // }

    .acc-item {
        .acc-bg {
            // &:not(:hover) {
            //     opacity: .6;
            // }


            &:hover {
                opacity: 0;
            }
        }
    }

    .accordion-wrapper {
        &:hover {
            .acc-bg {
                opacity: .6;
            }
        }
    }

    .accordion-wrapper {
        .acc-item {
            &:hover {
            .acc-bg {   
                    opacity: 0;
                }
            }

        }
    }

}
        @media (max-width: 700px) {
            .accordion {
                .accordion-wrapper {
                    width: 100%;
                    display: block;
                }

                .acc-item {
                    display: block;
                    width: 100%;
                }

                .acc-item:hover {
                    width: 100%;

                    .acc-bg {
                        opacity: .7;
                    }
                }
            }
        }