.stage {
    position: relative;

    .vce-text-block{
        width:90%;
        margin:auto; 
    }
    h1, p{
        text-shadow: 1px 1px 2px #000000;
    }
    h1{
        font-size: 80pt !important;
        line-height:normal; 
    }
    p{
        width:60%;
        margin-top:0;
        font-size:16pt;
    }
    @media screen and (max-width: 800px) {
        h1{
            font-size: 40pt !important;
            line-height:normal; 
        }
        p{
            width:100%;
        }
    } 
    @media screen and (max-width: 400px) {
        h1{
            font-size: 30pt !important;
            line-height:normal; 
        }
    } 


    &:before {
        content: ''; 
        position: absolute;
        display: block;
        width: 30px; 
        height: 50px;
        margin-left: auto;
        margin-right: auto; 
        left: 0;
        right: 0;  
        z-index: 1; 
        bottom:20px; 
        background-image:url('../../img/arrow-down.svg');
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
    } 

    // &:after {
    //     content: '';
    //     position: absolute;
    //     display: block; 
    //     width: 1px;
    //     height: 50px;
    //     background-color:white;
    //     margin-left: auto;
    //     margin-right: auto; 
    //     left: 0;
    //     right: 0; 
    //     z-index: 1;
    //     bottom:0; 
    // }
}

#scroll-down {
    position: absolute;
    bottom: 30px;
    left: 5%;
    opacity: .5;
    color: white;
    z-index: 2;
}