header {
    position:fixed;
    z-index: 1000;

    height: 58px;
    // padding-top: 20px;
    // max-width:1200px;
    width:100%;
    margin:auto;
    // //background-color: #ddd;
    // color: #555;
    // margin-bottom: 20px;
    // padding-bottom: 20px;
    // padding-top: 20px;

    display:flex !important;
    justify-content: space-between;

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out;

    background-color:white;

    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2); 
    
    &.scrollUp{
    transform: translateY(-80px);
    } 

    #logo {
        display: none;
        width: 10%;
       // height: 100px;
 
      //  background-image: url('../../img/amazonas.svg');
        background-size: contain; 
        background-repeat: no-repeat;
 
    }

     
}

