// @import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond|Open+Sans+Condensed:300&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i&display=swap');
@import url("https://use.typekit.net/szx4oub.css");

body {


    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;

    font-size: 12pt;
    line-height: 22pt;
    letter-spacing: .6pt;

    font-size: 11pt;
    line-height: 20pt;
    letter-spacing: .7pt;
    position: relative;
    color: #403e3c;
}


#stage h1 {
    // font-family: 'Cormorant Garamond', sans-serif;

    // text-align: left;

    // font-size: 5vw !important;
    // width: 100%;
    // font-weight: 500;
    // margin:0 !important;

    // position: relative;
    // overflow: hidden;
    // height: 5vw;

    font-family: garamond-premier-pro, serif;
    text-align: left;
    font-size: 8vw !important;
    width: 100%;
    letter-spacing: 10pt;
    font-weight: 500;
    margin: 0 !important;
    position: relative;
    overflow: hidden;
    height: 11vw;
}

h1 {
    font-family: garamond-premier-pro, serif;
    text-align: left;
    font-size: 40pt !important;
    width: 100%;
    letter-spacing: 1pt;
    font-weight: 500;
    margin: 0 !important;
    color: #403e3c;
}

.hidetext {
    position: absolute;
    // top: 100px;
}

h2 {
    // font-family: garamond-premier-pro, serif;
    // font-size: 24pt !important;
    // line-height: 32pt;
    // margin: 0;
    // font-weight: 400;
    font-family: garamond-premier-pro, serif;
    font-size: 30pt !important;
    line-height: 35pt;
    margin: 0;
    font-weight: 400;
    // margin-top: 20px;
    margin-bottom: 40px;
    letter-spacing: 1.4pt;

}

.fullscreenslide,
h3 {
    font-family: garamond-premier-pro, serif;
    text-align: left;
    font-size: 18pt !important;
    line-height: 21pt;
    font-weight: 400;
    width: 100%;
    margin-bottom: 20px;
}

.fullscreenslide {
    color: white;
}

h4 {
    font-family: garamond-premier-pro, serif;
    color: #403e3c;
    font-weight: normal;
    text-transform: uppercase;
}

h5 {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-size: 10pt;
    line-height: 20pt;
    letter-spacing: 1.4pt;
    font-weight: 500 !important;
    color: #403e3c;
    text-transform: uppercase;
}

a,
p {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;

    font-size: 12pt;
    line-height: 22pt;
    letter-spacing: .6pt;
    position: relative;
    color: #403e3c;
}

// a{
//     a{ 
//         &:hover{
//             text-decoration:underline;
//         } 
//     } 
// }

a {
    text-decoration: none;
    color: #676562;

    &:hover {
        text-decoration: underline;
    }
}

b,
strong {
    font-weight: 600 !important;
}

.wp-caption,
.metatext {
    p {
        font-family: neue-haas-grotesk-display, sans-serif;
        font-weight: 500;
        font-size: 10pt;
        line-height: 18pt;
        letter-spacing: .5pt;
        position: relative;
        color: #898d8e;
    }
}


blockquote,
blockquote p {

    // position: relative;
    // font-family: garamond-premier-pro, serif;
    // font-size: 30pt !important;
    // line-height: 40pt;
    // margin: 0;
    // font-weight: 400;
    // letter-spacing: 1.4pt;

    position: relative;
    font-family: garamond-premier-pro, serif;
    font-size: 22pt !important;
    line-height: 30pt;
    margin: 0;
    font-weight: 400;
    letter-spacing: 1.4pt;
}

blockquote {
    margin-top: 16px;
    margin-left: 50px;

    &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: -40px;
        background-image: url('../../img/icon-quote.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        width: 30px;
        height: 30px;
    }

    small {
        font-family: neue-haas-grotesk-display, sans-serif;
        font-weight: 500;

        font-size: 10pt;
        line-height: 22pt;
        letter-spacing: 1.4pt;
        position: relative;
        color: #403e3c;
        text-transform: uppercase;

        &:before {

            content: '';
            display: block;
            position: absolute;
            left: -30px;
            top: 8px;
            height: 2px;
            width: 25px;
            background-color: #403e3c;
        }
    }
}

ul {
    padding: 0;

    li {
        list-style: none;

        font-family: neue-haas-grotesk-display, sans-serif;
        font-weight: 500;

        font-size: 10pt;
        line-height: 22pt;
        letter-spacing: 1.4pt;
        position: relative;
        color: #403e3c;
        // text-transform: uppercase;
        //margin-left:20px;

        // &:before {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     left: -20px;
        //     top: 14px;
        //     height: 1px;
        //     width: 12px;
        //     background-color: #403e3c; 
        // }
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.vce-simple-image-slider {
    ul {
        li {
            margin: 0;
        }
    }
}


figcaption {
    font-family: neue-haas-grotesk-text, sans-serif;
    line-height: 12pt;
    font-size: 8pt;
    letter-spacing: 1.5px;
    position: relative; 
    color: #aaa;
    font-weight: 300; 
    font-style: normal !important;    
    margin-top:8px !important; 
} 

.dropcap {
    font-size: 60pt;
    display: inline;
    line-height: 53pt;
    padding-right: 15px; 
    padding-top: 5px;
    float: left;
    color: black;
    margin: 0;

 
}