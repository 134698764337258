.vce-classic-accordion-panel-heading {
    border-left: none;
    border-right: none; 
    border-top: none;
    //border-bottom: 1px solid #403e3c;
    // background-color:#F2EFE8;
}

.vce-classic-accordion-panel-title {}

.vce-classic-accordion-panel {
    border: none !important;
}

.vce-classic-accordion-panel-title {
    span {
        font-family: neue-haas-grotesk-display, sans-serif;
        font-size: 10pt;
        line-height: 20pt;
        letter-spacing: 1.4pt;
        font-weight: 500 !important;
        color: #403e3c;
        text-transform: uppercase;
    }
}

.vce-classic-accordion-element-container {
    .vce-row {
        width: 100% !important; 
        margin-top:30px;  
        margin-bottom:30px;
        p{
            font-family: neue-haas-grotesk-display, sans-serif;
            font-weight: 500;
            font-size: 10pt;
            line-height: 18pt;
            letter-spacing: .5pt;
            position: relative; 
            // color: #898d8e;
        }
    }
}

.behindthescenes, 
.vce-classic-accordion{
    position:relative; 
    // border-top: 1px solid #403e3c; 
   // border-top: solid 1px rgba(137,141,142,0.3);
    background-color:#EBE8E0;  
    .vce-row-content{
        width:98%;  
        margin:auto;
    }
    &:before{
        content:'Mehr zu dieser Reportage';
        display:block;
        position:absolute;
        top:-50px; 
        font-family: neue-haas-grotesk-display, sans-serif;
        font-size: 10pt;
        line-height: 20pt;
        letter-spacing: 1.4pt;
        font-weight: 600 !important;
        color: #403e3c;
        text-transform: uppercase;
    } 
    &:after{
        content:''; 
        display:block;
        position:absolute;
        top:-50px;
        right:0;
        width: 27px;
        height: 35px;
        background-image:url('../../img/more.svg');
        background-size:contain;
        background-repeat:no-repeat;
        background-position: center; 
    }
    a,
    p{
        font-family: neue-haas-grotesk-display, sans-serif;
        font-weight: 500;
        font-size: 10pt;
        line-height: 18pt;
        letter-spacing: .5pt;
        position: relative; 
        // color: #898d8e;
    }
}