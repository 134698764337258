footer{
    // height:500px;
    width:100%;
    //background-color:#FCFBFD;
    border-top: solid 1px rgba(137,141,142,0.3);
    a,
    p{
        
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 500;


    font-size: 10pt;
    line-height: 20pt;
    letter-spacing: .7pt;
    position: relative;
    color: #898d8e;   
    }
    section{
        width:90%;
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:auto;
        padding-bottom: 80px;
        padding-top: 80px;
    .col{
        width:30%;

    }
 
    ul{ 
        padding:0; 
        list-style:none;
        li{
            margin-top: 15px;
            margin-bottom: 15px;
         
        }
    }
    }
    .sm-container{
        display: flex;
       flex-direction: row;
       justify-content:space-between;
       width:110px;
        .icon{
            width:30px;
            height:30px;
            display:block;
            background-repeat: no-repeat;
            background-size:contain;
            background-position: center;
        }
        #fb{
            background-image: url('../../img/fb.svg');
        }
        #tw{
            background-image: url('../../img/tw.svg');
        }
        #vim{
            background-image: url('../../img/vim.svg');
        }
        #insta{
            background-image: url('../../img/insta.svg');
        }
    }
    #copyright{
        background-color:#FCFBFD;
        width:100%;
        color:white;
        padding-top:20px;
        padding-bottom:20px;
        text-align: center;
        border-top: solid 1px rgba(137,141,142,0.3);
    }
} 

@media screen and (max-width: 800px) {
    footer{
        section{
            flex-direction:column;
        }
    }
}